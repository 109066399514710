import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { ScrollElement } from 'react-scroll'

import { Section, Title } from '../common'
import { LayoutContext } from '../Layout'

const AQUA_THEME_SECTION_QUERY = graphql`
  query AQUA_THEME_SECTION_QUERY {
    imgMobile: file(name: { eq: "aqua-theme-mobile" }, ext: { eq: ".jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopLaptop: file(name: { eq: "aqua_laptop_big" }, ext: { eq: ".png" }) {
      id
      childImageSharp {
        fixed(width: 2004, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const Image = styled(Img)`
  margin-left: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  @media screen and (min-width: 768px) {
    margin-left: 0;
  }
`
const DesktopImage = styled(Img)`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 0;
  width: 2004px;
  transform: translateX(-50%);
`

const AfterSectionImage = () => {
  const { windowWidth } = useContext(LayoutContext)
  const { imgMobile, desktopLaptop } = useStaticQuery(AQUA_THEME_SECTION_QUERY)

  if (windowWidth < 768) {
    return (
      <Image
        key={imgMobile?.id}
        fluid={imgMobile?.childImageSharp?.fluid}
        alt="Aqua theme interface"
      />
    )
  }

  return (
    <div style={{ position: 'relative', marginTop: -21 }}>
      <DesktopImage
        key={desktopLaptop?.id}
        fixed={desktopLaptop?.childImageSharp?.fixed}
        alt="Aqua theme interface"
      />
    </div>
  )
}

const AquaTheme = props => {
  const { className } = props
  const { windowWidth } = useContext(LayoutContext)

  return (
    <Section
      name="aqua-theme"
      className={className}
      afterContainer={<AfterSectionImage />}
    >
      <Row>
        <Col xs={12} className="text-md-center">
          <Title>
            Meet Light <span className="Text-Aqua">Aqua</span> Theme
          </Title>
        </Col>
        <Col xs={12} md={{ span: 8, offset: 2 }} className="text-md-center">
          <p>
            {windowWidth && windowWidth > 767
              ? `Also in the first release you can find “aqua” light theme, that supports native macos accent and backgrounds color scheme with our edites. Submit your thoughts in contacts and meet them realized in the next releases.`
              : `First release contains native “aqua” light theme. Submit your thoughts in contacts and meet them realized in the next releases.`}
          </p>
        </Col>
      </Row>
    </Section>
  )
}

AquaTheme.propTypes = {
  className: PropTypes.string,
}

export default ScrollElement(styled(AquaTheme)`
  padding-top: 70px;
  padding-bottom: 70px;

  h2 {
    margin-bottom: 29px;
  }

  p {
    margin-bottom: 37px;
    line-height: 2;
    letter-spacing: 0.1px;
  }

  @media screen and (min-width: 768px) {
    padding-bottom: 6px;
    p {
      margin-bottom: 0;
      line-height: 1.63;
      letter-spacing: 0.6px;
    }
  }
`)
