import React, { useReducer, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import produce from 'immer'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { Input, Button, Select } from '../common'
import { validate } from '../../utils'
import { LayoutContext } from '../Layout'

const selectOptions = [
  '3d Artist',
  'Motion Designer',
  'Game Designer',
  'Investor',
  'Other',
].map(string => ({ value: string, label: string }))

const initialState = {
  fullName: '',
  email: '',
  jobTitle: '',
  suggestions: '',
  loading: false,
  fullNameError: '',
  emailError: '',
  result: undefined,
}

const reducer = (state, { type, payload }) =>
  produce(state, draft => {
    if (type === 'UPDATE_ITEMS') {
      const keys = Object.keys(payload)
      let i
      for (i = keys.length; i--; ) {
        draft[keys[i]] = payload[keys[i]]
      }
    }
  })

const Subscribe = props => {
  const { className } = props
  const [state, dispatch] = useReducer(reducer, initialState)
  const { openModal } = useContext(LayoutContext)

  const handleValidation = e => {
    const { name, value, type } = e.target
    const [error] = validate(value, [
      'isNotEmpty',
      ...(type === 'email' ? ['isEmail'] : []),
    ])
    dispatch({ type: 'UPDATE_ITEMS', payload: { [`${name}Error`]: error } })
  }

  const handleChange = e => {
    const { value, name } = e.target
    dispatch({ type: 'UPDATE_ITEMS', payload: { [name]: value } })
  }

  const handleSimulateSyntheticChange = value => {
    handleChange({ target: { name: 'jobTitle', value } })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const {
      email,
      fullName,
      jobTitle,
      suggestions,
      emailError,
      fullNameError,
    } = state

    const valO = [
      { target: { value: fullName, name: 'fullName', type: 'text' } },
      { target: { value: email, name: 'email', type: 'email' } },
    ]

    valO.forEach(i => handleValidation(i))

    if (emailError || fullNameError) return null

    dispatch({ type: 'UPDATE_ITEMS', payload: { loading: true } })

    let result
    try {
      result = await addToMailchimp(email, {
        FNAME: fullName,
        JTITLE: jobTitle,
        SUGGESTION: suggestions,
      })

      if (result.result === 'error') {
        openModal({ component: 'Error', props: { ...result } })
      } else {
        openModal({ component: 'ThankYou' })
      }
    } catch (err) {
      result = err
      openModal({ component: 'Error', props: { ...result } })
    }

    dispatch({ type: 'UPDATE_ITEMS', payload: { loading: false } })
  }

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <fieldset
        disabled={state.loading}
        aria-busy={state.loading}
        className="d-flex flex-column"
      >
        <Input
          name="fullName"
          placeholder="Full Name"
          value={state.fullName}
          required
          onChange={handleChange}
          errorMessage={state.fullNameError}
          onBlur={handleValidation}
        />

        <Input
          name="email"
          type="email"
          placeholder="Email"
          value={state.email}
          required
          onChange={handleChange}
          errorMessage={state.emailError}
          onBlur={handleValidation}
        />

        <Select
          name="jobTitle"
          placeholder="Choose job title"
          options={selectOptions}
          value={
            state.jobTitle
              ? { value: state.jobTitle, label: state.jobTitle }
              : undefined
          }
          isDisabled={state.loading}
          onChange={({ value }) => {
            // simulate synthetic event
            const e = {
              target: {
                value,
                type: 'select-one',
                name: 'jobTitle',
              },
            }
            return handleChange(e)
          }}
          handleInputChange={handleSimulateSyntheticChange}
          onCreateOption={handleSimulateSyntheticChange}
        />

        <Input
          name="suggestions"
          tag="textarea"
          placeholder="Type a message here…"
          value={state.suggestions}
          onChange={handleChange}
        />

        <Button
          type="submit"
          aria-label="Submit the form"
          className="w-100 mx-0"
          disabled={state.emailError || state.fullNameError}
        >
          {!state.loading ? 'Get' : 'Sending...'}
        </Button>
      </fieldset>
    </Form>
  )
}

Subscribe.propTypes = {
  className: PropTypes.string,
}

export default styled(Subscribe)`
  button {
    &:disabled {
      display: block;
      background-color: ${({ theme }) => theme.colors.grey};
      border-color: ${({ theme }) => theme.colors.grey};
    }
  }
`
