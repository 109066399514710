import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { ScrollElement } from 'react-scroll'

import { Section, Title } from '../common'
import { LayoutContext } from '../Layout'

const MODALS_AND_SETTINGS_SECTION_QUERY = graphql`
  query MODALS_AND_SETTINGS_SECTION_QUERY {
    img: file(name: { eq: "modals-and-settings" }, ext: { eq: ".png" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1376, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const Image = styled(Img)`
  margin-left: auto;
  margin-right: -15px;
  width: calc(100% - 15px);
  max-width: 1376px;
  @media screen and (min-width: 576px) {
    width: 1376px;
    margin-left: 0;
    margin-right: 0;
  }
`

const ModalsAndSettings = props => {
  const { img } = useStaticQuery(MODALS_AND_SETTINGS_SECTION_QUERY)
  const { className } = props
  const { windowWidth } = useContext(LayoutContext)

  return (
    <Section name="modals" className={className}>
      <Row>
        <Col xs={12} className="text-md-center">
          <Title>Modals & Settings Panels</Title>
        </Col>
        <Col xs={12} md={{ span: 8, offset: 2 }} className="text-md-center">
          <p>
            {windowWidth && windowWidth > 767
              ? `Material Editors, System popups, Dropdown menus, Render settings menus, Sidebar settings panels were implemented with the same design rools, that created from the zero to fully consistent interface design system.`
              : `Updated Material Editors, System popups, Dropdown menus, Render settings menus, Sidebar settings panels.`}
          </p>
        </Col>
        <Col xs={12}>
          <Image
            key={img?.id}
            fluid={img?.childImageSharp?.fluid}
            alt="Modals and Settings Panels"
          />
        </Col>
      </Row>
    </Section>
  )
}

ModalsAndSettings.propTypes = {
  className: PropTypes.string,
}

export default ScrollElement(styled(ModalsAndSettings)`
  padding-top: 63px;
  padding-bottom: 65px;

  h2 {
    margin-bottom: 29px;
  }

  p {
    margin-bottom: 28px;
    line-height: 2;
    letter-spacing: 0.1px;
  }

  @media screen and (min-width: 768px) {
    padding-bottom: 137px;
    p {
      margin-bottom: 90px;
      line-height: 1.63;
      letter-spacing: 0.6px;
    }
  }
`)
