import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import { ScrollElement } from 'react-scroll'

import { Section, Title } from '../common'
import { LayoutContext } from '../Layout'

const HOMEPAGE_WHY_QUERY = graphql`
  query HOMEPAGE_WHY_QUERY {
    desktopBG: file(name: { eq: "3-sectors-bg" }, ext: { eq: ".svg" }) {
      id
      publicURL
    }
    differentStyleIcons: file(
      name: { eq: "different-style-icons" }
      ext: { eq: ".svg" }
    ) {
      id
      publicURL
    }
    noNativeThemes: file(
      name: { eq: "no-native-themes" }
      ext: { eq: ".svg" }
    ) {
      id
      publicURL
    }
    oldUserInterface: file(
      name: { eq: "old-user-interface" }
      ext: { eq: ".svg" }
    ) {
      id
      publicURL
    }
  }
`

const Icon = styled.img`
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 8px;
  margin-top: -2px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`
const T = styled(Title)`
  margin-bottom: 30px;
  @media screen and (min-width: 768px) {
    margin-bottom: 100px;
  }
`
const BackgroundImage = styled.img`
  height: 119%;
  width: 100%;
  position: absolute;
  top: -9%;
  left: -37px;
`

const Why = props => {
  const { className } = props
  const { windowWidth } = useContext(LayoutContext)
  const {
    desktopBG,
    differentStyleIcons,
    noNativeThemes,
    oldUserInterface,
  } = useStaticQuery(HOMEPAGE_WHY_QUERY)
  return (
    <Section name="why" className={className}>
      {windowWidth > 767 && (
        <BackgroundImage src={desktopBG?.publicURL} alt="Triangle Background" />
      )}
      <Row>
        <Col xs={12} className="text-md-center">
          <T>Why</T>
        </Col>
        <Col xs={12} md={6} className="WhySection-FirstTwoReasons">
          <Row className="px-md-3">
            <Col xs={12} md={{ span: 8, offset: 2 }} className="px-md-3">
              <h3 className="Text-Primary d-flex align-items-center">
                <Icon
                  src={oldUserInterface.publicURL}
                  alt="Old User Interface"
                />
                Old User Interface
              </h3>
              <p>
                {windowWidth && windowWidth > 768
                  ? `UI has strokes, shadows, inner shadows that distract from the essence - the content. The overall look of the ui perceived like old but still working style.`
                  : `UI has strokes, shadows, inner shadows that distract from the essence - the content. The overall look of the ui perceived like old.`}
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} className="WhySection-FirstTwoReasons">
          <Row className="px-md-3">
            <Col xs={12} md={{ span: 8, offset: 2 }} className="px-md-4">
              <h3 className="Text-Violet d-flex align-items-center d-md-block">
                <Icon
                  src={noNativeThemes.publicURL}
                  alt="No Native Themes Support"
                />
                No Native Themes{' '}
                <span className="d-none d-lg-inline">Support</span>
              </h3>
              <p>
                Current user interface has not color schemes as dark and light
                macOS native interfaces. The most popular design tools have it
                and this color schemes have become as one of the main ui
                features.
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={{ span: 6, offset: 3 }}>
          <Row className="px-md-3">
            <Col
              xs={12}
              md={{ span: 8, offset: 2 }}
              className="pl-md-5 pr-md-3"
            >
              <h3 className="Text-Blue d-flex align-items-center">
                <Icon
                  src={differentStyleIcons.publicURL}
                  alt="Different Style Icons"
                />
                Different Style Icons
              </h3>
              <p>
                Icons designed in multiple styles. Have different bound save
                zones. Are not aligned on pixel grid. Have gradients, shadow and
                borders.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Section>
  )
}

Why.propTypes = {
  className: PropTypes.string,
}

export default ScrollElement(styled(Why)`
  padding-top: 35px;
  padding-bottom: 72px;
  z-index: 1;
  position: relative;
  h3 {
    line-height: 18px;
    margin-bottom: 11px;
  }
  @media screen and (min-width: 768px) {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 63px;
    padding-bottom: 128px;
    h3 {
      line-height: 1;
      margin-bottom: 14px;
    }
  }
  .WhySection-FirstTwoReasons {
    margin-bottom: 15px;
    @media screen and (min-width: 991px) {
      margin-bottom: 175px;
    }
  }
`)
