import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import {
  Hero,
  Why,
  IconsRevised,
  Layouts,
  MobileCTA,
  ModalsAndSettings,
  AquaTheme,
  GetEarlyAccess,
} from '../components/homepage'
import { LayoutContext } from '../components/Layout'

const Homepage = props => {
  const {
    location: { pathname },
  } = props
  const { windowWidth } = useContext(LayoutContext)

  return (
    <>
      <Hero pathname={pathname} />
      <Why />
      <IconsRevised />
      <Layouts />
      {windowWidth < 768 && <MobileCTA pathname={pathname} />}
      <ModalsAndSettings />
      <AquaTheme />
      <GetEarlyAccess />
    </>
  )
}

Homepage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Homepage
