import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import CountUp from 'react-countup'
import handleViewport from 'react-in-viewport'
import { ScrollElement } from 'react-scroll'

import { Section, Title } from '../common'
import { LayoutContext } from '../Layout'

const HOME_ICONS_REVISED_QUERY = graphql`
  query HOME_ICONS_REVISED_QUERY {
    mobile: file(name: { eq: "revised-block" }, ext: { eq: ".svg" }) {
      id
      publicURL
    }
    desktop: file(name: { eq: "revised-block-desktop" }, ext: { eq: ".svg" }) {
      id
      publicURL
    }
    rulerPencil: file(name: { eq: "ruler-pencil" }, ext: { eq: ".svg" }) {
      id
      publicURL
    }
    sameBounds: file(name: { eq: "same-bounds" }, ext: { eq: ".svg" }) {
      id
      publicURL
    }
    labels: file(name: { eq: "labels" }, ext: { eq: ".svg" }) {
      id
      publicURL
    }
    grid: file(name: { eq: "grid" }, ext: { eq: ".svg" }) {
      id
      publicURL
    }
  }
`

const T = styled(Title)`
  margin-bottom: 35px;
  @media screen and (min-width: 768px) {
    margin-bottom: 29px;
  }
`
const MainImage = styled.img`
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
  @media screen and (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`
const ListIcons = styled.img`
  display: inline-block;
  width: 31px;
  height: 31px;
  margin-right: 20px;
`

const IconsRevised = props => {
  const [countUpStart, setCount] = useState(false)
  const { className, inViewport, forwardedRef } = props
  const { windowWidth } = useContext(LayoutContext)
  const {
    mobile,
    desktop,
    rulerPencil,
    sameBounds,
    labels,
    grid,
  } = useStaticQuery(HOME_ICONS_REVISED_QUERY)

  useEffect(() => {
    if (inViewport) setCount(true)
    return undefined
  }, [inViewport])

  return (
    <Section name="icons-revised" className={className}>
      <Row>
        <Col xs={12} className="text-md-center">
          <T>Icons Revised</T>
        </Col>
        <Col xs={12} md={{ span: 8, offset: 2 }} className="text-md-center">
          <p>
            All icons was redesigned carefully on pixel grid in the same style
            rools, with recignized cinema 4d colors, inside safe and subsafe
            bounds.
          </p>
        </Col>
        <Col xs={12} md={6} className="IconsRevised-LeftColumn">
          <MainImage
            src={windowWidth < 768 ? mobile?.publicURL : desktop?.publicURL}
            key={windowWidth < 768 ? mobile?.id : desktop?.id}
            alt="Icons Revised"
            className="pr-md-4"
          />
        </Col>
        <Col xs={12} md={6} className="IconsRevised-RightColumn">
          <ul className="list-unstyled pl-md-4">
            <li>
              <ListIcons
                src={rulerPencil?.publicURL}
                key={rulerPencil?.id}
                alt="Consistent style rules for all icons"
              />
              <span>
                {windowWidth < 768
                  ? 'Consistent style icons'
                  : 'Consistent style rules for all icons'}
              </span>
            </li>
            <li>
              <ListIcons
                src={sameBounds?.publicURL}
                key={rulerPencil?.id}
                alt="The same safe and subsafe icons bounds in buttons"
              />
              <span>
                {windowWidth < 768
                  ? 'Same safe bounds'
                  : 'The same safe and subsafe icons bounds in buttons'}
              </span>
            </li>
            <li>
              <ListIcons
                src={labels?.publicURL}
                key={labels?.id}
                alt="Recognisable Cinema 4D colors schemes"
              />
              <span>
                {windowWidth < 768
                  ? 'Recognisable colors'
                  : 'Recognisable Cinema 4D colors schemes'}
              </span>
            </li>
            <li>
              <ListIcons
                src={grid?.publicURL}
                key={grid?.id}
                alt="All interface icons are handcrafted on pixel grid"
              />
              <span>
                {windowWidth < 768
                  ? 'Based on pixel grid'
                  : 'All interface icons are handcrafted on pixel grid'}
              </span>
            </li>
            <li ref={forwardedRef} className="d-md-flex align-items-md-center">
              <CountUp
                start={!countUpStart ? 1 : 0}
                end={300}
                duration={1}
                suffix="+"
                className="IconsRevised-Counter"
              />{' '}
              <span className="IconsRevised-CounterLabel ml-md-3">
                crafted icons
              </span>
            </li>
          </ul>
        </Col>
      </Row>
    </Section>
  )
}

IconsRevised.propTypes = {
  className: PropTypes.string,
  inViewport: PropTypes.bool,
  forwardedRef: PropTypes.object,
}

IconsRevised.defaultProps = {
  inViewport: false,
}

export default ScrollElement(
  handleViewport(styled(IconsRevised)`
    padding-top: 47px;
    padding-bottom: 25px;

    h2 {
      margin-bottom: 0 !important;
      line-height: 0.92;
    }
    @media screen and (min-width: 768px) {
      padding-bottom: 125px;
      h2 {
        line-height: 1.08;
      }
      p {
        margin-bottom: 50px;
      }
    }

    .IconsRevised-LeftColumn {
      margin-top: 29px;
      @media screen and (min-width: 768px) {
        padding-right: 33px;
        margin-top: 38px;
      }
    }
    .IconsRevised-RightColumn {
      opacity: 0.9;
      font-size: 2rem;
      line-height: 1.3;
      letter-spacing: normal;
      margin-top: 47px;

      @media screen and (min-width: 768px) {
        padding-left: 33px;
        margin-top: 38px;
      }

      li {
        margin-bottom: 30px;
        &:last-of-type {
          margin-top: -8px;
        }
        &:not(:last-of-type) {
          margin-top: 0;
          display: flex;
          align-items: center;
        }
        @media screen and (min-width: 768px) {
          margin-bottom: 32px;
        }
      }
    }
    .IconsRevised-Counter {
      font-size: 4.1rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      display: block;
      @media screen and (min-width: 768px) {
        font-size: 5.8rem;
        display: inline;
      }
    }
    .IconsRevised-CounterLabel {
      opacity: 0.23;
      font-size: 4.1rem;
      font-weight: bold;
    }
  `)
)
