import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { ScrollElement } from 'react-scroll'

import { Section, Title } from '../common'
import { Subscribe } from '../forms'
import { LayoutContext } from '../Layout'

const GetEarlyAccess = props => {
  const { className } = props
  const { windowWidth } = useContext(LayoutContext)

  return (
    <Section name="get-early-access" className={className}>
      <Row className="text-md-center">
        <Col xs={12}>
          <Title>
            Get Early Access & <span className="Text-Pink">50% Discount</span>
          </Title>
        </Col>
        <Col xs={12} md={{ span: 6, offset: 3 }} className="px-md-0">
          <p className="mx-md-n3">
            {windowWidth && windowWidth > 767
              ? `To get the Access input the fields and make your thoughts and suggestions. If you are investor, write about your intentions and we will contact you asap.`
              : `Get Early Access, sign up in the fields below. If you are investor, type about your intentions and your contacts will be required.`}
          </p>
        </Col>
        <Col
          xs={12}
          sm={{ span: 8, offset: 2 }}
          lg={{ span: 6, offset: 3 }}
          className="px-md-5"
        >
          <Subscribe className="px-md-4" />
        </Col>
      </Row>
    </Section>
  )
}

GetEarlyAccess.propTypes = {
  className: PropTypes.string,
}

export default ScrollElement(styled(GetEarlyAccess)`
  padding-top: 65px;
  padding-bottom: 70px;
  h2 {
    margin-bottom: 29px;
  }
  p {
    margin-bottom: 36px;
    line-height: 2;
    letter-spacing: 0.1px;
  }
  @media screen and (min-width: 768px) {
    padding-bottom: 192px;
    padding-top: 45px;
    p {
      margin-bottom: 87px;
      line-height: 1.63;
      letter-spacing: 0.6px;
    }
  }
`)
