import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import Img from 'gatsby-image'
import cx from 'classnames'
import { scroller } from 'react-scroll'

import { Section, Button } from '../common'
import { LayoutContext } from '../Layout'

const HOMEPAGE_HERO_QUERY = graphql`
  query HOMEPAGE_HERO_QUERY {
    mobileLaptop: file(name: { eq: "hero_laptop" }, ext: { eq: ".png" }) {
      id
      childImageSharp {
        fluid(maxWidth: 767) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    heroBG: file(name: { eq: "laptop" }, ext: { eq: ".png" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1362) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const Subtitle = styled.p`
  font-size: 1.5rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
  margin-top: 14px;
  margin-bottom: 40px;
  opacity: 0.7;

  @media screen and (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 26px;
    opacity: 1;
    font-size: 1.8rem;
    line-height: 1.78;
  }
`
const DesktopBg = styled(Container)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding-top: 48px;
  z-index: -1;

  .gatsby-image-wrapper {
    &,
    &:before,
    &:after {
      width: 118%;
      margin-left: -9%;
      margin-right: -9%;
    }
  }
`

const HeroAfterContainer = () => {
  const { mobileLaptop, heroBG } = useStaticQuery(HOMEPAGE_HERO_QUERY)
  const { windowWidth } = useContext(LayoutContext)

  if (windowWidth < 768) {
    return (
      <Img
        fluid={mobileLaptop.childImageSharp?.fluid}
        key={mobileLaptop.id}
        alt="Laptop"
        className="w-100 Homepage-MobileLaptop"
      />
    )
  }

  return (
    <DesktopBg>
      <Img
        key={heroBG?.id}
        fluid={heroBG?.childImageSharp?.fluid}
        alt="Hero bg"
      />
    </DesktopBg>
  )
}

const Hero = props => {
  const { className, pathname } = props

  return (
    <Section
      className={cx('flex-column', className)}
      afterContainer={<HeroAfterContainer />}
    >
      <Row>
        <Col xs={12} className="text-md-center">
          <h1>
            Adore Cinema 4D with the{' '}
            <span className="Text-Pink text-nowrap">New interface</span>
          </h1>
          <Subtitle>
            Ideate, Create stunning 3d masterpieces with the new modern Cinema
            4D user interface plugin
          </Subtitle>
          <div>
            <Button
              modifiers={['fixedSize']}
              onClick={() => {
                if (pathname === '/') {
                  return scroller.scrollTo('get-early-access', { smooth: true })
                }
                return navigate('/#get-early-access')
              }}
            >
              Get Early Access
            </Button>
            <Button modifiers={['fixedSize']} disabled>
              See Pricing
            </Button>
          </div>
        </Col>
      </Row>
    </Section>
  )
}

Hero.propTypes = {
  className: PropTypes.string,
  pathname: PropTypes.string.isRequired,
}

export default styled(Hero)`
  display: flex;
  align-items: center;
  padding-top: 36px;
  background-color: transparent;
  position: relative;

  @media screen and (min-width: 768px) {
    padding-top: calc(246px + 48px);
    padding-bottom: calc(224px + 411px - 113px);
  }

  .Homepage-MobileLaptop {
    margin-top: 48px;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
`
