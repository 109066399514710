import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'react-bootstrap'

import { scroller } from 'react-scroll'
import { Section, Button } from '../common'

const MOBILE_CALL_TO_ACTION_SECTION_QUERY = graphql`
  query MOBILE_CALL_TO_ACTION_SECTION_QUERY {
    appIcon: file(name: { eq: "app-icon" }, ext: { eq: ".png" }) {
      id
      childImageSharp {
        fixed(width: 59, height: 66) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const MobileCTA = props => {
  const { className, pathname } = props
  const { appIcon } = useStaticQuery(MOBILE_CALL_TO_ACTION_SECTION_QUERY)
  return (
    <Section className={className}>
      <Row>
        <Col xs={12} className="text-center">
          <Img
            key={appIcon?.id}
            fixed={appIcon?.childImageSharp?.fixed}
            alt="logo"
            style={{ marginBottom: 18 }}
          />
          <h2>
            <span className="Text-Pink">Be the First</span> trying tidykit{' '}
            <span className="Text-Pink">today</span>
          </h2>
          <p>
            Create stunning 3d masterpeases with the new Cinema 4D interface
            plugin.
          </p>
          <Button
            onClick={() => {
              if (pathname === '/') {
                return scroller.scrollTo('get-early-access', { smooth: true })
              }
              return navigate('/#get-early-access')
            }}
          >
            Get Early Access
          </Button>
        </Col>
      </Row>
    </Section>
  )
}

MobileCTA.propTypes = {
  className: PropTypes.string,
  pathname: PropTypes.string,
}

export default styled(MobileCTA)`
  background-color: #000;
  padding-top: 73px;
  padding-bottom: 72px;

  h2 {
    &,
    & > span {
      font-size: 3.1rem !important;
      line-height: 1.29;
      letter-spacing: 0.24px;
    }
  }
  p {
    letter-spacing: 0.1px;
    margin-bottom: 61px;
    line-height: 2;
  }
  button {
    width: auto;
  }
`
