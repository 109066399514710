import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { ScrollElement } from 'react-scroll'

import { Section, Title } from '../common'
import { LayoutContext } from '../Layout'

const HOMEPAGE_LAYOUT_SECTION_QUERY = graphql`
  query HOMEPAGE_LAYOUT_SECTION_QUERY {
    oneInterface: file(name: { eq: "dark_theme" }, ext: { eq: ".png" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1140, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    fiveInterfaces: file(name: { eq: "5-interfaces" }, ext: { eq: ".png" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const Image = styled(Img)`
  &,
  &:before,
  &:after {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
    @media screen and (min-width: 768px) {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
  &.Layouts-DarkTheme {
    margin-bottom: 25px;
    box-shadow: 0 10px 24px 4px rgba(0, 0, 0, 0.1);
    @media screen and (min-width: 768px) {
      margin-bottom: 98px;
    }
  }
`

const Layouts = props => {
  const { className } = props
  const { windowWidth } = useContext(LayoutContext)
  const { oneInterface, fiveInterfaces } = useStaticQuery(
    HOMEPAGE_LAYOUT_SECTION_QUERY
  )
  return (
    <Section
      name="layouts"
      className={className}
      afterContainer={
        windowWidth > 575 && (
          <Image
            key={fiveInterfaces?.id}
            fluid={fiveInterfaces?.childImageSharp?.fluid}
            style={{ maxWidth: 1600, marginLeft: 'auto', marginRight: 'auto' }}
            alt="five interfaces"
          />
        )
      }
    >
      <Row>
        <Col xs={12} className="text-md-center">
          <Title>Layouts</Title>
        </Col>
        <Col xs={12} md={{ span: 8, offset: 2 }} className="text-md-center">
          <p>
            Reimagined panel buttons, fields, and dropdown menus in Standart,
            Animate, Model, 3D Paint and Sculpt layouts.
          </p>
        </Col>
        <Col xs={12}>
          <Image
            key={oneInterface?.id}
            fluid={oneInterface?.childImageSharp?.fluid}
            alt="interface"
            className="Layouts-DarkTheme"
          />
        </Col>
        {windowWidth < 576 && (
          <Col xs={12}>
            <Image
              key={fiveInterfaces?.id}
              fluid={fiveInterfaces?.childImageSharp?.fluid}
              alt="five interfaces"
            />
          </Col>
        )}
      </Row>
    </Section>
  )
}

Layouts.propTypes = {
  className: PropTypes.string,
}

export default ScrollElement(styled(Layouts)`
  overflow-x: hidden;
  padding-top: 68px;
  padding-bottom: 47px;
  h2 {
    margin-bottom: 33px;
  }
  p {
    margin-bottom: 38px;
    line-height: 2;
    letter-spacing: 0.1px;
  }
  @media screen and (min-width: 768px) {
    padding-bottom: 143px;
    p {
      margin-bottom: 85px;
      line-height: 1.63;
      letter-spacing: 0.6px;
    }
  }
`)
